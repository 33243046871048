<script setup>
  const props = defineProps({
    classes: String
  })
</script>

<template>
  
<svg  :class="props.classes" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0797 46.6081C11.7277 46.6081 1.67969 36.5601 1.67969 24.2081C1.67969 11.8561 11.7277 1.80811 24.0797 1.80811C36.4317 1.80811 46.4797 11.8561 46.4797 24.2081C46.4797 36.5601 36.4317 46.6081 24.0797 46.6081ZM24.0797 2.60811C12.1597 2.60811 2.47969 12.3041 2.47969 24.2081C2.47969 36.1121 12.1597 45.8081 24.0797 45.8081C35.9997 45.8081 45.6797 36.1281 45.6797 24.2081C45.6797 12.2881 35.9837 2.60811 24.0797 2.60811Z" fill="#473A4E"/>
<path d="M24.0793 44.2562C35.1515 44.2562 44.1273 35.2804 44.1273 24.2082C44.1273 13.136 35.1515 4.16016 24.0793 4.16016C13.007 4.16016 4.03125 13.136 4.03125 24.2082C4.03125 35.2804 13.007 44.2562 24.0793 44.2562Z" fill="url(#paint0_linear_8713_324159)"/>
<mask id="path-3-inside-1_8713_324159" fill="white">
<path d="M25.3419 11.928C25.7825 11.928 26.1215 12.2669 26.1215 12.7076C26.1215 13.1483 25.7825 13.4873 25.3419 13.4873H21.1724C20.7318 13.4873 20.3928 13.1483 20.3928 12.7076C20.3928 12.2669 20.7318 11.928 21.1724 11.928H25.3419ZM25.3419 11.25H21.1724C20.3589 11.25 19.7148 11.8941 19.7148 12.7076C19.7148 13.5211 20.3589 14.1652 21.1724 14.1652H25.3419C26.1554 14.1652 26.7995 13.5211 26.7995 12.7076C26.7995 11.8941 26.1215 11.25 25.3419 11.25Z"/>
</mask>
<path d="M25.3419 11.928C25.7825 11.928 26.1215 12.2669 26.1215 12.7076C26.1215 13.1483 25.7825 13.4873 25.3419 13.4873H21.1724C20.7318 13.4873 20.3928 13.1483 20.3928 12.7076C20.3928 12.2669 20.7318 11.928 21.1724 11.928H25.3419ZM25.3419 11.25H21.1724C20.3589 11.25 19.7148 11.8941 19.7148 12.7076C19.7148 13.5211 20.3589 14.1652 21.1724 14.1652H25.3419C26.1554 14.1652 26.7995 13.5211 26.7995 12.7076C26.7995 11.8941 26.1215 11.25 25.3419 11.25Z" fill="black"/>
<path d="M25.3419 12.928C25.2992 12.928 25.2391 12.9089 25.1898 12.8596C25.1406 12.8104 25.1215 12.7503 25.1215 12.7076H27.1215C27.1215 11.7146 26.3348 10.928 25.3419 10.928V12.928ZM25.1215 12.7076C25.1215 12.6649 25.1406 12.6048 25.1898 12.5556C25.2391 12.5063 25.2992 12.4873 25.3419 12.4873V14.4873C26.3348 14.4873 27.1215 13.7006 27.1215 12.7076H25.1215ZM25.3419 12.4873H21.1724V14.4873H25.3419V12.4873ZM21.1724 12.4873C21.2151 12.4873 21.2752 12.5063 21.3245 12.5556C21.3737 12.6048 21.3928 12.6649 21.3928 12.7076H19.3928C19.3928 13.7006 20.1795 14.4873 21.1724 14.4873V12.4873ZM21.3928 12.7076C21.3928 12.7503 21.3737 12.8104 21.3245 12.8596C21.2752 12.9089 21.2151 12.928 21.1724 12.928V10.928C20.1795 10.928 19.3928 11.7146 19.3928 12.7076H21.3928ZM21.1724 12.928H25.3419V10.928H21.1724V12.928ZM25.3419 10.25H21.1724V12.25H25.3419V10.25ZM21.1724 10.25C19.8066 10.25 18.7148 11.3418 18.7148 12.7076H20.7148C20.7148 12.4463 20.9112 12.25 21.1724 12.25V10.25ZM18.7148 12.7076C18.7148 14.0734 19.8066 15.1652 21.1724 15.1652V13.1652C20.9112 13.1652 20.7148 12.9689 20.7148 12.7076H18.7148ZM21.1724 15.1652H25.3419V13.1652H21.1724V15.1652ZM25.3419 15.1652C26.7077 15.1652 27.7995 14.0734 27.7995 12.7076H25.7995C25.7995 12.9689 25.6031 13.1652 25.3419 13.1652V15.1652ZM27.7995 12.7076C27.7995 11.3267 26.6587 10.25 25.3419 10.25V12.25C25.5844 12.25 25.7995 12.4614 25.7995 12.7076H27.7995Z" fill="white" mask="url(#path-3-inside-1_8713_324159)"/>
<path d="M29.6094 14.3687L32.9992 17.0805" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M23.1328 25.7245L28.1158 19.9619" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M13.918 18.4023H20.2907" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M11.3398 20.9111H20.4244" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M9 23.3516H20.4235" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M23.2358 26.7753C23.8536 26.7753 24.3544 26.2745 24.3544 25.6567C24.3544 25.0389 23.8536 24.5381 23.2358 24.5381C22.618 24.5381 22.1172 25.0389 22.1172 25.6567C22.1172 26.2745 22.618 26.7753 23.2358 26.7753Z" fill="black" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M22.1172 15.5548V14.165" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M24.4922 15.5548V14.165" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M29.6797 17.8263L31.3068 15.7246" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M18.9644 16.5385C19.9474 16.0639 21.4389 15.5216 23.3033 15.6233C27.6422 15.8266 33.1675 19.4876 33.1675 25.4536C33.1675 30.8433 28.6252 35.1823 23.3033 35.2501C17.9474 35.284 13.4051 30.9111 13.2695 25.4536" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
<defs>
<linearGradient id="paint0_linear_8713_324159" x1="4.03125" y1="24.2082" x2="44.1113" y2="24.2082" gradientUnits="userSpaceOnUse">
<stop stop-color="#766082"/>
<stop offset="0.595" stop-color="#473A4E"/>
</linearGradient>
</defs>
</svg>


</template>
