<script setup>
import GainMileageCircle from "~/components/Vector/GainMileageCircle.vue";
import GainProcessCircle from "~/components/Vector/GainProcessCircle.vue";
import GainAgreementCircle from "~/components/Vector/GainAgreementCircle.vue";
import GainResellCircle from "~/components/Vector/GainResellCircle.vue";

const breakpoint = useBreakpoints();

const gains = [
  {
    title: 'Szybki proces',
    text: 'Decyzja finansowa w 15 minut, jedna prosta umowa na wszystkie usługi.',
    icon: GainProcessCircle
  },
  {
    title: 'Brak limitu kilometrów',
    text: 'Niezależnie od sposobu zakończenia umowy, nie obciążymy Cię opłatami za nadprzebieg.',
    icon: GainMileageCircle
  },
  {
    title: 'Elastyczne zakończenie umowy',
    text: 'Samochód możesz wymienić na nowy, wykupić na własność, przedłużyć finansowanie lub po prostu go oddać.',
    icon: GainAgreementCircle
  },
  {
    title: 'Gwarancja zwrotu',
    text: 'Możliwość przedterminowego zakończenia umowy, bez podawania przyczyny, z prostym rozliczeniem i bez opłat karnych.',
    icon: GainResellCircle
  },
]

</script>

<template>
  <div>
    <div class="2xl:grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-4 relative shadow-box-light print:shadow-none z-[10] print:border print:border-gray-300 px-6 py-6 rounded-lg">
      <template v-for="(gain,index) in gains">
        <div class="flex  mb-6 last:mb-0 2xl:mb-0">
          <div class="mr-2">
            <Component :is="gain.icon" :classes="'w-10 h-10'"/>
          </div>
          <div>
            <div class="font-normal text-base text-black w-full mb-2">{{ gain.title }}</div>
            <div class="font-extralight text-black w-full">{{ gain.text }}</div>
          </div>
        </div>

        <div v-if="!((index+1) % 2) && index + 1< gains.length" class="col-span-2"></div>
      </template>
    </div>
  </div>
</template>
