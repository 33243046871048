<script setup>
  const props = defineProps({
    classes: String
  })
</script>

<template>
    <svg :class="props.classes" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.0797 46.6081C11.7277 46.6081 1.67969 36.5601 1.67969 24.2081C1.67969 11.8561 11.7277 1.80811 24.0797 1.80811C36.4317 1.80811 46.4797 11.8561 46.4797 24.2081C46.4797 36.5601 36.4317 46.6081 24.0797 46.6081ZM24.0797 2.60811C12.1597 2.60811 2.47969 12.3041 2.47969 24.2081C2.47969 36.1121 12.1597 45.8081 24.0797 45.8081C35.9997 45.8081 45.6797 36.1281 45.6797 24.2081C45.6797 12.2881 35.9837 2.60811 24.0797 2.60811Z" fill="#473A4E"/>
        <path d="M24.0793 44.2562C35.1515 44.2562 44.1273 35.2804 44.1273 24.2082C44.1273 13.136 35.1515 4.16016 24.0793 4.16016C13.007 4.16016 4.03125 13.136 4.03125 24.2082C4.03125 35.2804 13.007 44.2562 24.0793 44.2562Z" fill="url(#paint0_linear_8713_322925)"/>
        <path d="M12.8242 33.7501L18.6784 12.8853" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.2422 12.8853V16.1576" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.2422 19.3999V23.9632" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.2422 27.2354V33.75" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.8092 33.7501L27.9551 12.8853" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_8713_322925" x1="4.03125" y1="24.2082" x2="44.1113" y2="24.2082" gradientUnits="userSpaceOnUse">
        <stop stop-color="#766082"/>
        <stop offset="0.6" stop-color="#473A4E"/>
        </linearGradient>
        </defs>
</svg>
</template>
